import { useStore } from "vuex";
export async function getDetailsForModal() {
  const store = useStore();
  store.dispatch("fetchAllUsersList");
  store.dispatch("fetchBundles");
  store.dispatch("fetchPlans");
  store.dispatch("fetchLocationPackages", {
    locationType: "countries",
    params: {
      search: "",
      filter: "",
      page: 1,
      perPage: 150
    }
  });
  store.dispatch("fetchLocationPackages", {
    locationType: "regions",
    params: {
      search: "",
      filter: "",
      page: 1,
      perPage: 150
    }
  });
}
