import { PromoCodesDto } from "./../../api/interfaces/promo-codes/promo-codes.dto";
import { sortOrders } from "@/enums/main/sortOrders";
import { ITab } from "@/store/interfaces/common";
import { computed, reactive, Ref, ref, watch } from "vue";
import { useStore } from "vuex";
import { useBaseAppLayout } from "../layout/useBaseAppLayout";
import { useBaseTable } from "../tables/useTable";
import { promoCodesTabs } from "./instanse/promoCodesTabs";
import { generatePromoCodesHead } from "./modules/generatePromoCodesHead";
import { generatePromoCodesRows } from "./modules/generatePromoCodesRows";
import { getDetailsForModal } from "./modules/getAllForModal";
import { getPromoCodesSortConfig } from "./modules/getPromoCodesSortConfig";

export function usePromoCodes() {
  const store = useStore();
  const promoCodesModel = computed(() => {
    return store.getters.promoCodesList(
      selectedTab.value.value ? "personal" : "nonPersonal"
    );
  });

  const totalRowsCount = computed(() => {
    return store.getters.totalPromoCodes(
      selectedTab.value.value ? "personal" : "nonPersonal"
    );
  });

  const currentSortKeyLocationName = "title";

  const initialSortOptions = getPromoCodesSortConfig(
    currentSortKeyLocationName
  );
  const {
    filter,
    searchQuery,
    selectedFilterOption,
    searchHandler
  } = useBaseAppLayout({
    initialSearchQuery: ""
  });

  const currentViewConfiguration = reactive({ page: 1, perPage: 10 });
  const selectedTab: Ref<ITab> = ref({ ...promoCodesTabs[0] });
  const requestOptions = reactive({
    ...currentViewConfiguration,
    search: searchQuery.value,
    value: selectedTab.value
  });
  const localeCurrentSort = ref({
    keyName: initialSortOptions.byTitle.keyName,
    order: initialSortOptions.byTitle.order
  });
  const generateTableRows = generatePromoCodesRows(localeCurrentSort);
  const {
    viewConfiguration,
    rows,
    currentSort,
    changeViewConfiguration,
    changeSorting,
    sortedModel
  } = useBaseTable({
    initialSortOptions: initialSortOptions,
    initialViewConfiguration: { page: 1, perPage: 10 },
    model: promoCodesModel,
    generateRows: generateTableRows,
    onViewConfigurationChanged: ({ page, perPage }) => {
      if (requestOptions.perPage !== perPage) {
        requestOptions.page = 1;
      } else {
        requestOptions.page = page;
      }

      requestOptions.perPage = perPage;
    }
  });

  async function fetchPromoCodes(): Promise<boolean> {
    return await getPromoCodes();
  }

  watch(
    currentSort,
    sort => {
      const { keyName = "", order = sortOrders.turnOff } =
        Object.values(sort).find(({ order }) => order !== sortOrders.turnOff) ||
        {};

      localeCurrentSort.value = { keyName, order };
    },
    { immediate: true, deep: true }
  );

  watch(searchQuery, query => {
    requestOptions.search = query;
    changeViewConfiguration({
      page: 1,
      perPage: currentViewConfiguration.perPage
    });
    fetchPromoCodes();
  });

  watch(viewConfiguration, fetchPromoCodes, { deep: true, immediate: false });
  watch(
    selectedTab,
    () => {
      if (viewConfiguration.page !== 1) {
        changeViewConfiguration({
          page: 1,
          perPage: currentViewConfiguration.perPage
        });
      } else fetchPromoCodes();
    },
    { deep: true, immediate: false }
  );
  const tableHead = computed(() =>
    generatePromoCodesHead(currentSort, currentSortKeyLocationName)
  );

  async function getPromoCodes() {
    await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("fetchPromoCodes", {
      viewConfig: { ...viewConfiguration, personal: selectedTab.value.value }
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    await store.dispatch("setAppLoading", false);
    return success;
  }
  async function actionWithPromoCodes(data: PromoCodesDto | null, id: number) {
    await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("actionWithPromoCode", {
      data: { ...data },
      id: id ? id : null
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
      await store.dispatch("setAppLoading", false);
    }
    return success;
  }
  return {
    tabs: promoCodesTabs,
    tableHead,
    rows,
    totalRowsCount,
    filter,
    currentSort,
    searchQuery,
    sortedModel,
    selectedFilterOption,
    viewConfiguration,
    pageTitle: selectedTab.value.title,
    initialSortOptions,
    currentSortKeyLocationName,
    promoCodesModel,
    selectedTab,
    fetchPromoCodes,
    searchHandler,
    changeViewConfiguration,
    changeSorting,
    getDetailsForModal,
    actionWithPromoCodes,
    changeTabHandler(idx: ITab) {
      selectedTab.value = <ITab>idx;
    }
    // changeStatusHandler,
    // changeOperatorStatusHandler,
    // changeEsimZoneHandler,
    // changeEsimServerHandler
  };
}
