
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";
import { ISelectOption } from "@/hooks/esim/location-packages/modules/useLocationPackagesSelect";
import CountrySelect from "../ui/Country/CountrySelect.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";

export default defineComponent({
  name: "ModalPromoCodeTargeting",
  components: {
    AppButton,
    // AppButton,
    CountrySelect
  },
  props: {
    users: {
      type: Array as PropType<ISelectOption[]>,
      default: () => [{ id: 1, selected: false, name: "" }]
    },
    countries: {
      type: Array as PropType<ISelectOption[]>,
      default: () => [{ id: 1, selected: false, name: "" }]
    },
    regions: {
      type: Array as PropType<ISelectOption[]>,
      default: () => [{ id: 1, selected: false, name: "" }]
    },
    subregions: {
      type: Array as PropType<ISelectOption[]>,
      default: () => [{ id: 1, selected: false, name: "" }]
    },
    bundles: {
      type: Array as PropType<ISelectOption[]>,
      default: () => [{ id: 1, selected: false, name: "" }]
    },
    plans: {
      type: Array as PropType<ISelectOption[]>,
      default: () => [{ id: 1, selected: false, name: "" }]
    },
    buttonDisabled: {
      type: Boolean,
      default: true
    },
    editData: {
      type: Boolean,
      default: true
    },
    usersSelect: {
      type: Function as PropType<Function>,
      required: true
    },
    countriesSelect: {
      type: Function as PropType<Function>,
      required: true
    },
    regionsSelect: {
      type: Function as PropType<Function>,
      required: true
    },
    subregionsSelect: {
      type: Function as PropType<Function>,
      required: true
    },
    bundlesSelect: {
      type: Function as PropType<Function>,
      required: true
    },
    plansSelect: {
      type: Function as PropType<Function>,
      required: true
    },
    checkedCountries: {
      type: Array as PropType<{ id: number }[]>,
      required: true
    },
    checkedRegions: {
      type: Array as PropType<{ id: number }[]>,
      required: true
    },
    checkedSubregions: {
      type: Array as PropType<{ id: number }[]>,
      required: true
    },
    checkedBundles: {
      type: Array as PropType<{ id: number }[]>,
      required: true
    },
    checkedPlans: { type: Array as PropType<{ id: number }[]>, required: true },
    checkedUsers: { type: Array as PropType<{ id: number }[]>, required: true }
  },
  data() {
    return {};
  },
  watch: {},
  methods: {
    selectUsersHandler(data: any) {
      this.usersSelect(data.map((id: number) => ({ id })));
    },
    selectCountryHandler(data: any) {
      this.countriesSelect(data.map((id: number) => ({ id })));
      this.plansSelect([]);
      this.bundlesSelect([]);
    },
    selectRegionHandler(data: any) {
      this.regionsSelect(data.map((id: number) => ({ id })));
      this.plansSelect([]);
      this.bundlesSelect([]);
    },
    selectSubregionHandler(data: any) {
      this.subregionsSelect(data.map((id: ISelectOption) => ({ id })));
      this.plansSelect([]);
      this.bundlesSelect([]);
    },
    selectBundlesHandler(data: any) {
      this.bundlesSelect(data.map((id: number) => ({ id })));
    },
    selectPlansHandler(data: any) {
      this.plansSelect(data.map((id: number) => ({ id })));
    },
    submit() {
      this.$emit("submit");
    }
  }
});
