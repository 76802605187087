
import TabsDialog from "@/components/ui/Modal/Dialog/TabsDialog.vue";
import { defineComponent } from "@vue/runtime-core";
import ModalPromoCodeDetails from "@/components/PromoCodes/ModalPromoCodeDetails.vue";
import ModalPromoCodeTargeting from "@/components/PromoCodes/ModalPromoCodeTargeting.vue";
import { PropType } from "vue";
import { ILocationCountry, ILocationRegion } from "@/store/locations";
import { IUserAccount } from "@/store/interfaces/accounts/IUserAccount";
import {
  IBundle,
  IPlan,
  ISubregion
} from "@/api/services/packages/esim/location-packages/types";
import { PromoCodesDto } from "@/api/interfaces/promo-codes/promo-codes.dto";
import { mapDropDownSelect } from "@/hooks/dropdown/mapDropDown";

const tabs = [
  { id: 1, title: "Details" },
  { id: 2, title: "Targeting" }
];

export default defineComponent({
  name: "CreatePromoCodesModal",
  components: {
    TabsDialog,
    ModalPromoCodeDetails,
    ModalPromoCodeTargeting
  },
  props: {
    id: { type: Number, default: 0 },
    editData: {
      type: Object as PropType<PromoCodesDto | null>,
      default: () => {}
    },
    countries: {
      type: Object as PropType<ILocationCountry[]>,
      required: true
    },
    regions: {
      type: Object as PropType<ILocationRegion[]>,
      required: true
    },
    users: {
      type: Object as PropType<IUserAccount[]>,
      required: true
    },
    bundles: {
      type: Object as PropType<IBundle[]>,
      required: true
    },
    plans: {
      type: Object as PropType<IPlan[]>,
      required: true
    }
  },
  emits: {
    submit: null
  },
  data() {
    const defaultData = {
      title: "",
      discountType: "",
      discountAmount: 0,
      activeFrom: "",
      activeTo: "",
      unique: false,
      active: false,
      personal: false,
      users: [],
      countries: [],
      regions: [],
      subregions: [],
      plans: [],
      bundles: []
    } as PromoCodesDto;
    return {
      tabs,
      selectedTab: 0,
      defaultData,
      data: defaultData,
      localOpened: true,
      windowLoading: false,
      buttonDisabled: true,
      isError: false,
      checkedType: false,
      withCountry: false,
      withSubregions: false,
      subregions: [] as any
    };
  },
  computed: {
    updateDropDownUsersList() {
      const temp = this.data.users.map((user: any) => user.id);
      return mapDropDownSelect(this.users, temp);
    },
    updateDropDownCoutriesList() {
      const temp = this.data.countries.map((country: any) => country.id);
      return mapDropDownSelect(this.countries, temp);
    },
    updateDropDownRegionsList() {
      const temp = this.data.regions.map((region: any) => region.id);
      return mapDropDownSelect(
        this.regions.filter(r => {
          if (!this.withCountry && !this.withSubregions) {
            return true;
          }
          if (this.withCountry && !this.withSubregions) {
            return !!r.countries.length;
          }
          if (!this.withCountry && this.withSubregions) {
            return !!r.subregions.length;
          }
          return false;
        }),
        temp
      );
    },
    updateDropDownSubregionsList() {
      const temp = this.data.subregions.map((subregion: any) => subregion.id);
      return mapDropDownSelect(this.subregions, temp);
    },
    updateDropDownBundlesList(): IBundle[] {
      const temp = this.data.bundles.map(bundle => bundle.id);
      const newBundles: IBundle[] = [];

      if (this.data.countries.length > 0) {
        const countries = this.data.countries.map(country =>
          this.countries.find(el => el.id === country.id)
        );

        const allCountryBundles = countries.flatMap(country => {
          const countryBundles = country?.countryPlans.flatMap((cp: any) => {
            if (cp.plan?.packages?.length) {
              return cp.plan.packages;
            }
            return [];
          });

          return countryBundles;
        });

        newBundles.push(
          ...this.bundles.filter(bundle =>
            allCountryBundles.some(cb => cb.product === bundle.i_product)
          )
        );
      }

      if (this.data.subregions.length > 0) {
        const subregions = this.data.subregions.map(subregion =>
          this.subregions.find((el: any) => el.id === subregion.id)
        );

        const allSubregionBundles = subregions.flatMap(subregion => {
          const subregionBundles = subregion.subregionPlans.flatMap(
            (cp: any) => {
              if (cp.active && cp.plan?.packages?.length) {
                return cp.plan.packages;
              }
              return [];
            }
          );

          return subregionBundles;
        });

        newBundles.push(
          ...this.bundles.filter(bundle =>
            allSubregionBundles.some(cb => cb.product === bundle.i_product)
          )
        );
      }

      if (this.data.regions.length > 0 && this.withCountry) {
        const regions = this.data.regions.map(region =>
          this.regions.find(el => el.id === region.id)
        );

        const allRegionBundles = regions.flatMap(region => {
          const regionBundles = region?.regionPlans.flatMap((cp: any) => {
            if (cp.active && cp.plan?.packages?.length) {
              return cp.plan.packages;
            }
            return [];
          });

          return regionBundles;
        });

        newBundles.push(
          ...this.bundles.filter(bundle =>
            allRegionBundles.some(cb => cb.product === bundle.i_product)
          )
        );
      }

      return mapDropDownSelect(newBundles, temp);
    },
    updateDropDownPlansList() {
      const temp = this.data.plans.map((plan: any) => plan.id);
      let newPlans = [] as IPlan[] | [];
      if (!!this.data.countries.length) {
        const newCountry = this.data.countries.map((country: any) =>
          this.countries.find((el: any) => el.id === country.id)
        );
        newPlans = this.plans.filter((el: any) => {
          let isNotFound = false;
          newCountry.forEach((country: any) => {
            if (!country.countryPlans.length) {
              isNotFound = true;
            }
            const tempPlans = country.countryPlans
              .map((cp: any) => cp.plan)
              .flat(1);
            const isFound = tempPlans.find((tmp: any) => el.id === tmp.id);
            if (!isFound) {
              isNotFound = true;
            }
          });
          if (isNotFound) {
            return false;
          }
          return true;
        });
      }
      if (!!this.data.subregions.length) {
        const newSubregion = this.data.subregions.map((subregion: any) =>
          this.subregions.find((el: any) => el.id === subregion.id)
        );
        newPlans = this.plans.filter((el: any) => {
          let isNotFound = false;
          newSubregion.forEach((subregion: any) => {
            if (!subregion.subregionPlans.length) {
              isNotFound = true;
            }
            const tempPlans = subregion.subregionPlans
              .map((cp: any) => (cp.plan.active && cp.active ? cp.plan : null))
              .filter((filter: any) => filter !== null)
              .flat(1);
            const isFound = tempPlans.find((tmp: any) => el.id === tmp.id);
            if (!isFound) {
              isNotFound = true;
            }
          });
          if (isNotFound) {
            return false;
          }
          return true;
        });
      }
      if (!!this.data.regions.length && this.withCountry) {
        const newRegion = this.data.regions.map((region: any) =>
          this.regions.find((el: any) => el.id === region.id)
        );
        newPlans = this.plans.filter((el: any) => {
          let isNotFound = false;
          newRegion.forEach((region: any) => {
            if (!region.regionPlans.length) {
              isNotFound = true;
            }
            const tempPlans = region.regionPlans
              .map((cp: any) => (cp.plan.active && cp.active ? cp.plan : null))
              .filter((filter: any) => filter !== null)
              .flat(1);
            const isFound = tempPlans.find((tmp: any) => el.id === tmp.id);
            if (!isFound) {
              isNotFound = true;
            }
          });
          if (isNotFound) {
            return false;
          }
          return true;
        });
      }
      return mapDropDownSelect(newPlans, temp);
    }
  },
  watch: {
    data: {
      handler(data: PromoCodesDto) {
        if (!!data.users?.length) {
          this.data.personal = true;
        }
        if (
          data.title.length >= 3 &&
          !!data.discountType.length &&
          data.discountAmount !== 0 &&
          data.activeFrom &&
          data.activeFrom !== "Invalid date" &&
          data.activeTo &&
          !this.isError
        ) {
          this.buttonDisabled = false;
        } else this.buttonDisabled = true;
      },
      immediate: true,
      deep: true
    },
    isError(data: boolean) {
      if (data) {
        this.buttonDisabled = data;
      }
    },
    editData: {
      handler(data: PromoCodesDto | null) {
        if (data) {
          this.data = data;
        } else this.clear();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    usersSelect(data: IUserAccount[]) {
      this.data.users = data;
    },
    countrySelect(data: ILocationCountry[]) {
      this.data.countries = data;
    },
    regionSelect(data: ILocationRegion[]) {
      this.withCountry = this.regions.some(
        region => data[0]?.id === region.id && !!region.countries.length
      );
      this.withSubregions = this.regions.some(
        region => data[0]?.id === region.id && !!region.subregions.length
      );
      this.data.regions = data;
      this.subregions = data
        .map((item: ILocationRegion) => {
          return (
            this.regions.find((el: any) => el.id === item.id)?.subregions ??
            null
          );
        })
        .filter((el: any) => el !== null)
        .flat(1);
    },
    subregionSelect(data: ISubregion[]) {
      this.data.subregions = data;
    },
    bundlesSelect(data: IBundle[]) {
      this.data.bundles = data;
    },
    plansSelect(data: IPlan[]) {
      this.data.plans = data;
    },
    typeSelect(data: string) {
      this.data.discountType = data;
    },
    amountSelect(data: number) {
      this.data.discountAmount = data;
    },
    dateSelect({
      activeFrom,
      activeTo
    }: {
      activeFrom: string;
      activeTo: string;
    }) {
      this.data.activeFrom = activeFrom;
      this.data.activeTo = activeTo;
    },
    titleChange(title: string) {
      this.data.title = title;
    },
    typeChange(type: string, value: boolean) {
      if (type === "r") {
        this.data.unique = false;
      } else if (type === "u") {
        this.data.unique = true;
      }
      this.checkedType = value;
    },
    statusChange(status: boolean) {
      this.data.active = status;
    },
    submitHandler() {
      this.$emit("submit", this.data);
      this.clear();
    },
    errorChangeHandler(err: boolean) {
      this.isError = err;
    },
    hideDialog() {
      this.clear();
    },
    clear() {
      this.selectedTab = 0;
      this.data = {
        title: "",
        discountType: "",
        discountAmount: 0,
        activeFrom: "",
        activeTo: "",
        unique: false,
        active: false,
        personal: false,
        users: [],
        countries: [],
        regions: [],
        subregions: [],
        plans: [],
        bundles: []
      } as PromoCodesDto;
    }
  }
});
