
import { defineComponent } from "@vue/runtime-core";
import AppInput from "@/components/Form/AppInput.vue";
import AppCheckbox from "@/components/Form/AppCheckbox.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import AppCalendar from "@/components/ui/Calendar/AppCalendar.vue";
import AppRadio from "@/components/Form/AppRadio.vue";
import { PromoCodesDto } from "@/api/interfaces/promo-codes/promo-codes.dto";
import { PropType } from "vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
interface dateForReq {
  activeFrom: string;
  activeTo: string;
}
export default defineComponent({
  name: "ModalPromoCodeDetails",
  components: {
    AppInputsValidationProvider,
    AppInput,
    AppCheckbox,
    AppButton,
    SelectInput,
    AppCalendar,
    AppRadio
  },
  props: {
    editData: {
      type: Object as PropType<PromoCodesDto | null>,
      default: () => {}
    },
    dateSelect: {
      type: Function,
      required: true
    },
    typeSelect: {
      type: Function,
      required: true
    },
    amountSelect: {
      type: Function,
      required: true
    },
    titleChange: {
      type: Function,
      required: true
    },
    buttonDisabled: {
      type: Boolean,
      default: true
    },
    changeType: {
      type: Function,
      required: true
    },
    changeStatus: {
      type: Function,
      required: true
    }
  },
  emits: {
    submit: null,
    errorChange: null
  },
  data() {
    return {
      title: "",
      discountAmount: [10, 20, 25, 30, 40, 45, 50, 60, 70, 100].map(
        (t, idx) => ({
          name: t + "%",
          value: t,
          id: idx,
          selected: false
        })
      ),
      discountType: ["Persantage", "Full Free"].map(t => ({
        name: t,
        id: t,
        selected: false
      })),
      selectedDiscountType: "",
      activeFrom: "",
      activeTo: "",
      active: false,
      unique: false,
      reusable: false,
      testString: "qwe"
    };
  },
  watch: {
    title(data: string) {
      this.titleChange(data);
    },
    active(data: boolean) {
      this.changeStatus(data);
    },
    editData: {
      handler(data: PromoCodesDto) {
        if (data) {
          const {
            id,
            unique,
            active,
            title,
            discountType,
            discountAmount,
            activeFrom,
            activeTo
          } = data;
          this.active = active;
          this.unique = unique;
          if (id && !unique) this.reusable = true;
          this.title = title;
          this.activeFrom = activeFrom;
          this.activeTo = activeTo;
          if (discountAmount !== 0) {
            this.selectDiscountAmount(
              this.discountAmount.find(el => el.value === discountAmount)?.id ||
                0
            );
          }
          this.selectDiscountType(
            this.discountType.find(el => el.name === discountType)?.id || ""
          );
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    selectDiscountType(id: string) {
      this.discountType.forEach(t => (t.selected = t.id === id));
      this.selectedDiscountType =
        this.discountType.find(t => t.selected)?.name || "";
      if (this.selectedDiscountType === "Full Free") {
        this.discountAmount.forEach(t => (t.selected = t.value === 100));
        this.amountSelect(100);
        this.reusable = false;
        this.unique = true;
        this.changeType("u", true);
      }
      this.typeSelect(this.selectedDiscountType);
    },
    selectDiscountAmount(id: number) {
      this.discountAmount.forEach(t => (t.selected = t.id === id));
      this.amountSelect(this.discountAmount.find(t => t.selected)?.value);
    },
    selectDate(data: dateForReq) {
      this.dateSelect(data);
    },
    onChangeReusableType({ target }: any) {
      this.changeType("r", target.checked);
    },
    onChangeUniqueType({ target }: any) {
      this.changeType("u", target.checked);
    },
    submit() {
      this.$emit("submit");
    },
    onChangeErrorState(error: boolean) {
      this.$emit("errorChange", error);
    }
  }
});
