import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPromoCodeDetails = _resolveComponent("ModalPromoCodeDetails")!
  const _component_ModalPromoCodeTargeting = _resolveComponent("ModalPromoCodeTargeting")!
  const _component_TabsDialog = _resolveComponent("TabsDialog")!

  return (_openBlock(), _createBlock(_component_TabsDialog, {
    modelValue: _ctx.selectedTab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTab) = $event)),
    tabs: _ctx.tabs,
    opened: _ctx.localOpened,
    "modal-loading": _ctx.windowLoading,
    title: _ctx.editData?.id ? 'Edit' : 'Create',
    class: "create-location-modal",
    onHide: _ctx.hideDialog
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ModalPromoCodeDetails, {
        "edit-data": _ctx.editData?.id ? _ctx.editData : _ctx.data,
        "date-select": _ctx.dateSelect,
        "type-select": _ctx.typeSelect,
        "amount-select": _ctx.amountSelect,
        "change-status": _ctx.statusChange,
        "title-change": _ctx.titleChange,
        "change-type": _ctx.typeChange,
        "button-disabled": _ctx.buttonDisabled,
        onErrorChange: _ctx.errorChangeHandler,
        onSubmit: _ctx.submitHandler
      }, null, 8, ["edit-data", "date-select", "type-select", "amount-select", "change-status", "title-change", "change-type", "button-disabled", "onErrorChange", "onSubmit"]),
      _createVNode(_component_ModalPromoCodeTargeting, {
        users: _ctx.updateDropDownUsersList,
        countries: _ctx.updateDropDownCoutriesList,
        regions: _ctx.updateDropDownRegionsList,
        subregions: _ctx.updateDropDownSubregionsList,
        bundles: _ctx.updateDropDownBundlesList,
        plans: _ctx.updateDropDownPlansList,
        "checked-countries": _ctx.data.countries,
        "checked-regions": _ctx.data.regions,
        "checked-subregions": _ctx.data.subregions,
        "checked-bundles": _ctx.data.bundles,
        "checked-users": _ctx.data.users,
        "checked-plans": _ctx.data.plans,
        "edit-data": !!_ctx.editData?.id,
        "button-disabled": _ctx.buttonDisabled,
        "users-select": _ctx.usersSelect,
        "countries-select": _ctx.countrySelect,
        "regions-select": _ctx.regionSelect,
        "subregions-select": _ctx.subregionSelect,
        "bundles-select": _ctx.bundlesSelect,
        "plans-select": _ctx.plansSelect,
        onSubmit: _ctx.submitHandler
      }, null, 8, ["users", "countries", "regions", "subregions", "bundles", "plans", "checked-countries", "checked-regions", "checked-subregions", "checked-bundles", "checked-users", "checked-plans", "edit-data", "button-disabled", "users-select", "countries-select", "regions-select", "subregions-select", "bundles-select", "plans-select", "onSubmit"])
    ]),
    _: 1
  }, 8, ["modelValue", "tabs", "opened", "modal-loading", "title", "onHide"]))
}